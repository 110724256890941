import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        font-size: 1em;
        text-decoration: none;
        cursor: pointer;

        border-radius: .5em;
        border: none;

        &.disabled {
            background-color: #909090 !important;
        }
    }

    .button-shadow {
        position: absolute;
        width: 80%;
        height: 40%;
        left: 10%;
        top: 70%;
        z-index: 1;
        opacity: 1;
        filter: blur(.5em);
        display: none;
    }

    a {
        text-decoration: none;
    }

    span {
        font-size: 1em;
        text-decoration: none;
        cursor: pointer;
    }

    .tooltip-content-mobile {
        font-size: .8em;
        color: #666666;
        text-align: center;
        display: none;
    }

    .button-icon-before,
    .button-icon-after {
        .arrow {
            width: 80px;
        }
    }

    .button-icon-before {
        margin-right: .5em;
    }

    .button-icon-after {
        margin-left: .5em;
    }

    /* Layouts */

    &.layout-default {
        .button {
            padding: .7em 1.5em;
        }
    }

    &.layout-wide {
        .button {
            padding: .7em 2.5em;
        }
    }

    &.layout-block {
        width: 100%;

        .button {
            padding: .7em 1em;
            width: 100%;
        }
    }

    /* Sizes */

    &.size-small {
        .button {
            font-size: .75em;
        }
    }

    &.size-medium {
        .button {
            font-size: .9em;
        }
    }

    &.size-large {
        .button {
            font-size: 1.15em;
        }
    }

    /* Styles */

    &.style-secondary {
        &.variant-outline {
            .button {
                background-color: transparent;
                color: #BA9449;
                border: .01em solid #CFB799;
                box-shadow: none;
            }

            &:hover {
                .button {
                    background-color: #CFB799;
                    color: white;
                }
            }
        }
    }

    &.style-gold {
        .button {
            .button-icon-after {
                transition: all .3s ease-in-out;
            }
        }

        &.variant-primary {
            .button {
                background-color: #BA9449;
                color: white;

                &:hover {
                    background-color: #CFB799;
                }
            }
        }

        &.variant-outline {
            .button {
                background-color: transparent;
                padding: 0;

                .button-content {
                    color: #BA9449;
                    text-transform: uppercase;
                    letter-spacing: .2em;
                }

                &:hover {
                    .button-content {
                        color: #CFB799;
                    }
                }
            }
        }

        &.variant-underline {
            .button {
                background-color: transparent;
                padding: 0;
                color: #BA9449;

                &:hover {
                    .button-content {
                        font-weight: 700;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &.style-green {
        &.variant-primary {
            .button {
                background-color: #819367;
                color: #FFFFFF;

                &:hover {
                    background-color: #9eae86;
                }
            }
        }
    }

    &.style-none {
        .button {
            background-color: transparent;
            color: white;
            box-shadow: none;
            padding: 0;

            .button-icon-after,
            .button-icon-before {
                margin: 0;
            }
        }

    }

    /* Modificators */

    &.disabled {
        .button {
            background-color: #666666 !important;
            color: white !important;
        }
    }

    &.shadow {
        .button-shadow {
            display: block;
        }
    }

    @media all and (max-width: ${vars.tabletS}) {
        .tooltip-content-mobile {
            display: block;
            margin-top: .3em;
        }

        .__react_component_tooltip {
            display: none !important;
        }
    }
`;
