import styled from '@emotion/styled';

export default styled.div`
    position: fixed;
    background-color: #262626;
    left: 0;
    right: 0;
    top: 70px;
    padding: 1em;
    height: calc(100% - 70px);
    overflow-y: auto;

    &.topbar {
        top: 100px;
        height: calc(100% - 100px);
    }

    .drop-down-wrapper {
        display: flex;
        flex-direction: column;
        padding: 1em;

        .gender-zone-buttons {
            padding: 0.3em;
            border-radius: 0.5em;
            display: flex;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.1);

            .gender-button {
                background-color: transparent;
                text-decoration: none;
                color: #FFFFFF;
                padding: 0.3em 1em;
                border-radius: 0.5em;
                font-size: 14px;
                transition: background-color 200ms ease;
                width: 100%;
                text-align: center;

                &::selection {
                    background-color: transparent;
                }

                &:hover {
                    background-color: rgba(0, 0, 0, 0.3);
                }

                &.active {
                    background-color: #BA9449;

                    &:hover {
                        background-color: #CFB799;
                    }
                }
            }
        }

        .pages {
            padding: 1em 1em 2em;
            margin: 2em 0;
            border-top: 2px solid rgb(62, 62, 62);
            border-bottom: 2px solid rgb(62, 62, 62);

            .pages-link {
                display: flex;
                flex-direction: column;

                .pages-link-about-links {
                    display: flex;
                    flex-direction: column;
                    background-color: rgb(49, 49, 49);
                    border-radius: 0.5em;
                    padding: 0.4em 0;

                    .pages-link {
                        &-anchor {
                            padding: 0.6em 1.6em;
                            font-size: 14px;
                            width: 100%;
                        }
                    }
                }

                &-anchor {
                    color: white;
                    text-decoration: none;
                    padding: 1em 0;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    gap: 0.6em;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

                    &::selection {
                        background-color: transparent;
                    }

                    img {
                        &::selection {
                            background-color: transparent;
                        }
                    }
                }

                &.highlighted {
                    display: inline-block;

                    .pages-link-anchor {
                        gap: 0;
                    }

                    a {
                        padding: 0.5em 0.5em 0.5em;
                        background-color: #ba9448;
                        border-radius: 0.5em;
                    }
                }
            }
        }

        .info-line {
            .info-line-nav-wrapper {
                display: flex;
                justify-content: center;

                .info-line-nav-content {
                    display: flex;
                    gap: 1em;
                    font-size: 16px;

                    * {
                        &::selection {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
`;
